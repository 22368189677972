import { Button, ButtonGroup } from "react-bootstrap";
import { useLocale } from "@/hooks/useLocale";
import { useLink } from "@/hooks/useLink";
import { placeholders } from "@/data/placeholders";

export const HeaderButton = ({ className }) => {
  const locale = useLocale();
  const link = useLink();

  return (
    <ButtonGroup className={className}>
      <Button variant="secondary" href={link("form.register")}>
        {placeholders[`header.button.label.${locale}`]}
        <span className="visually-hidden">and register your details</span>
      </Button>
    </ButtonGroup>
  );
};
