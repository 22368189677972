export const sourceflowEnums = {
  forms: {
    register: {
      en: "0572c9ee-a4e3-4c4b-8848-ee79fb350525",
    },
    contactUs: {
      en: "d67780de-408e-4ce8-b29f-67ef952853e9",
    },
    eventSignup: {
      en: "971b8aa4-c51e-4225-b6da-42ca4473cfca",
    },
    ir35: {
      en: "0c293198-9647-48a1-962f-5cb6d6bfb206",
    },
  },
  categories: {
    sectors: {
      id: "6da80293-5ea4-4328-bd3f-86b289986e15",
      insurance: "a30640cc-d6ad-491a-afe8-c6796cfffd45",
      banking: "b9e74fa7-9012-4628-95dd-8d74a647b157",
      technology: "9a9bfc11-5895-4940-ad6b-1647e9c1adee",
    },
    specialisms: {
      id: "26842a47-cade-451d-82c4-a494fff7132a",
    },
    type: {
      id: "c7980e6d-cc35-4deb-b3a4-5b7da2be6c0a",
      contract: "ed59ee3f-9736-428a-a3fd-21b21375b9b4",
      permanent: "c2a3a484-65e3-46ef-8db4-384645512816",
      both: "df487715-873f-43f1-9256-b986a998a9fd",
    },
    working_environment: {
      id: "e58bcf51-c579-4c53-8559-b02bc19b9171",
      any: "315f112c-60c6-4261-956f-9a813d55223b",
      hybrid: "99370d11-afbb-4297-a7b5-140461e90cdf",
      onsite: "43b775b7-6ac1-4183-9531-f8853bcda47a",
      remote: "e5a50c1c-9079-4682-9df5-c3fe4698bf7f",
    },
  },
  config: {
    baseUrl: "https://www.gravitasgroup.com.sg",
    siteName: "Gravitas Recruitment Group Singapore",
    socialLogo:
      "https://media.licdn.com/dms/image/C4D0BAQHN9687UaD-WQ/company-logo_200_200/0/1630579047955/gravitas_recruitment_group_uk_logo?e=2147483647&v=beta&t=y-gvCgEAM8WC-AzexuKI8iNgiJFtdab_W-IPJabbacA",
    recruitmentTypes: {
      contract: "contract",
      permanent: "permanent",
      retained: "retained",
      array: ["contract", "permanent", "retained"],
    },
  },
};

export default sourceflowEnums;
