export const placeholders = {
  "aside.register.button.en": "Upload CV",
  "aside.register.title.en": "Send your CV to Gravitas",
  "blog.card.button.en": "Read now",
  "blog.card.time.ago.en": "ago",
  "blog.card.time.new.en": "New",
  "details.button.close.en": "Read less",
  "details.button.open.en": "Read more",
  "event.card.button.en": "Find out more",
  "footer.address.en": "Gravitas Recruitment Group, 3rd Floor, 6 Bevis Marks, London, England EC3A 7BA",
  "footer.copyright.en": "&copy; Gravitas Group 2023",
  "footer.email.en": "info@gravitasgroup.com",
  "footer.form.text.en":
    'Or register your CV with us <a class="fw-bold" href="/register">here</a>.<br /><a class="small fw-bold" href="/terms-and-conditions">Terms & Conditions.</a>',
  "footer.form.title.en": "Get in touch",
  "footer.menu.title.en": "Information",
  "footer.offices.title.en": "Offices",
  "footer.siteby.en": "Site by",
  "footer.socials.label.en": "Follow us",
  "footer.title.en": 'We’re always looking for new opportunities. <a href="/contact-us">Let’s connect.</a>',
  "header.button.label.en": "Upload CV",
  "insight.link.back.en": "Back to insights",
  "job.card.apply.en": "Apply",
  "job.card.button.en": "Read more",
  "job.card.location.label.en": "Location",
  "job.card.new.en": "New",
  "job.card.salary.label.en": "Salary",
  "job.card.type.label.en": "Type",
  "job.link.back.en": "Back to job search",
  "jobs.search.button.en": "Search jobs",
  "jobs.search.location.label.en": "Location",
  "jobs.search.location.placeholder.en": "e.g. ‘Singapore’",
  "jobs.search.noResults.en": "Sorry there are no jobs matching your criteria at this time.",
  "jobs.search.noResults.withRedirect.en":
    "Sorry there are no jobs matching your criteria at this time. Redirecting in {seconds} seconds...",
  "jobs.search.query.label.en": "Keyword",
  "jobs.search.query.placeholder.en": "e.g. ‘developer’",
  "jobs.search.radius.label.en": "Radius",
  "jobs.search.resetSearch.en": "Reset Search",
  "jobs.search.roles.label.en": "Popular Roles",
  "jobs.search.specialisms.label.en": "Specialisms",
  "page.about.banner.button.en": "Visit our global group site",
  "page.about.banner.text.en":
    "<p>We are Gravitas. A global solutions company serious about talent, and serious about providing specialist recruitment and consultancy services. In Germany, The Netherlands and the UK we work within key niche talent shortage areas in the Technology, Insurance, Actuarial and Public Sectors.<br/>Our aim is to be a trusted partner, an employer of choice and to deliver an unrivalled service across contract, permanent recruitment and our consultancy solutions.</p>",
  "page.about.banner.title.en": "About us",
  "page.about.content.details.en":
    "We are part of a larger network of international offices, so you can be assured that we are legally, regulatory, and ethically compliant.",
  "page.about.content.tag.en": "Serious about talent.",
  "page.about.content.text.en":
    "<p>There are many reasons to choose to work with Gravitas. Here are some of reasons we are different from other agencies:</p><ul><li>True IT recruitment specialists: Each of our consultants only specialising in one technology area, within IT development, building their expertise, and offering valuable insights and guidance in Java and C#, for example.</li><li>A balance of speed-to-market and quality: Our team have built a specialist candidate network, backed by an award-winning compliance and operations team.</li><li>We do recruitment the right way: Integrity matters; we ask the right questions, follow set processes, and form trusted relationships. We don’t cut corners!</li></ul>",
  "page.about.content.title.en": "Why choose Gravitas.",
  "page.about.diversity.link.en": "Find out more",
  "page.about.diversity.text.en":
    "We’re committed to the promotion of D&I. Whether it’s working with businesses on their own diversity initiatives, leading conversations on the D&I agenda through our events program, ensuring our own working environment is fair and inclusive or collaborating on initiatives to encourage diversity.",
  "page.about.diversity.title.en": "Diversity & inclusion.",
  "page.awards.banner.text.en":
    "<p>Gravitas Recruitment Group is honoured to have received distinctive recognition for our world-class talent recruitment services within the human resources industry.</p>",
  "page.awards.banner.title.en": "Awards",
  "page.awards.feed.footer.en": 'Find out more <a href="/en/about-us">about us</a> here.',
  "page.awards.feed.text.en": "Some of our most significant awards from the past 3 years include:",
  "page.awards.gallery.title.en": "Gallery",
  "page.awards.insights.title.en": "Awards News",
  "page.awards.text.left.en": "",
  "page.awards.text.right.en": "",
  "page.contact.banner.text.en": "",
  "page.contact.banner.title.en": "Contact us.",
  "page.contact.form.text.en":
    '<p>Complete the form below and or call one of our local offices directly, using the numbers above. To register your CV with Gravitas <a href="/register">click here</a>.</p>',
  "page.contact.form.title.en": "Contact us.",
  "page.contact.office.map.en": "View map",
  "page.contact.submission-complete.content.en":
    "<p>Thank you for submitting your enquiry on our website. We will endeavor to get back to you within 1-2 working days.</p><p>Please note our working hours are 8.30am – 6pm, Monday to Friday.</p>",
  "page.contact.submission-complete.title.en": "Thank you",
  "page.contract.banner.button.en": "Get in touch",
  "page.contract.banner.text.en":
    "<p>Are you struggling to find the right IT professionals for your development project or team? Gravitas specialise in finding skilled and experienced IT freelancers across Germany.</p><p>We strive to make the process as stress-free and transparent as possible.</p>",
  "page.contract.banner.title.en": "Contract Recruitment<br /> Agency - IT Specialists",
  "page.contract.content.tag.en": "Serious about talent.",
  "page.contract.content.text.en":
    "<p>There are many advantages of hiring freelancers for contract IT development roles.</p><p>Contract recruitment is a great solution for short-term projects, arranging urgent staff cover, and providing access to specialist skills while remaining cost-effective.</p><p>By using Gravitas for your freelance hiring, you will have access to our exclusive freelancer developer networks, ensuring you not only fill your roles, but do so quickly and efficiently.</p><p>We’ve helped numerous businesses build their freelancer workforce, resulting in increased productivity, high-quality outcomes, reduced admin, and reduced costs.</p>",
  "page.contract.content.title.en": "The Benefits of Hiring Freelancers Through Gravitas.",
  "page.contract.grid.section-1.text.en":
    "<p>What sets us apart from other recruitment agencies is our transparent and friendly approach. We believe that great two-way communication is the key to finding the right freelancer for your needs. Our team of specialists take the time to listen to your business's specific requirements and provide ongoing support throughout the recruitment process.</p>",
  "page.contract.grid.section-1.title.en": "Why We Value Communication and Transparency",
  "page.contract.grid.section-2.text.en":
    "<p>At Gravitas, we leverage the power of recruitment technology and AI to streamline the recruitment process and reduce administrative burdens. Our award-winning compliance and operations team are on hand to process contracts, advise on legalities and set up time sheeting and invoicing, ensuring quality and speed. We take accuracy, compliance, and safety seriously and provide the necessary tools and support to our team and clients.</p>",
  "page.contract.grid.section-2.title.en": "The Use of Technology and Robust Processes",
  "page.dei.offerings.text.en": "",
  "page.dei.offerings.title.en": "Our DEI Offerings",
  "page.diversity.banner.button.en": "Get in touch",
  "page.diversity.banner.text.en":
    "<p>DE&I should be the cornerstone of any recruitment strategy. As a recruiter we have an important part to play in promoting diversity and advising on and practicing fair and inclusive recruitment processes.</p><p>With our own dedicated DE&I committee, we have a commitment to the promotion of DE&I. This includes working with hiring organisations on their own diversity initiatives, leading conversations through our events and education program, ensuring our own working environment is as inclusive as possible, and collaborating on initiatives like Programme One, which aims to recruit more black recruits into our own industry.</p>",
  "page.diversity.banner.title.en": "Diversity, Equity & Inclusion",
  "page.diversity.committee.cite.en": "Ryan Sullivan, <br/> DE&I Committee, Gravitas",
  "page.diversity.committee.quote.en":
    "I want to be part of the change that is happening around us. Being part of TA allows me to really show my passion for people and being part of the LGBTQ+ community has meant I want to ensure everyone can be recognised for their potential contribution.",
  "page.diversity.committee.text.en":
    "<p>The Gravitas Diversity, Equity & Inclusion Committee is a diverse group of people, passionate about this topic.</p><p>Their aim is to improve DE&I within Gravitas through positive action and the reduction of bias.</p><p>Also, to ensure that we collectively act on our crucial role in our wider communities, promoting DE&I through education, collaborations, consultancy and advice on diverse recruitment processes and practices.</p><p><a href='/contact-us'>Contact us</a> for more information.</p>",
  "page.diversity.committee.title.en": "Gravitas DE&I committee.",
  "page.diversity.content.tag.en": "Serious about talent.",
  "page.diversity.programme.one.details.en": "",
  "page.diversity.programme.one.text.en": "",
  "page.diversity.programme.one.title.en": "Programme One.",
  "page.diversity.programme.one.video.en": "",
  "page.event.button.signup.en": "Sign up now",
  "page.event.hasPast.button.en": "Find your next event",
  "page.event.hasPast.message.en": "This event has already taken place.",
  "page.event.hosted.en": "Hosted by",
  "page.events.banner.description.en":
    "<p>Our highly regarded events program focuses on connecting communities, through collaboration, networking and knowledge-sharing. Whether it’s the intimate and private space of a roundtable, the open forum of one of our lively panel discussions or the camaraderie experienced at a networking social; our events give you the chance to experience a range of perspectives on different topics, build your profile and expand your expertise. Checkout our calendar below to see our upcoming events.</p>",
  "page.events.banner.title.en": "Events",
  "page.events.noEvents.text.en": "No Events found.",
  "page.events.section.past.title.en": "Past Events.",
  "page.events.section.upcoming.title.en": "Upcoming Events.",
  "page.hiring.banner.button.en": "Get in touch",
  "page.hiring.banner.text.en": "",
  "page.hiring.banner.title.en": "Hiring Solutions.",
  "page.hiring.content.tag.en": "Serious about talent.",
  "page.hiring.content.text.en":
    "<p>We love to do things differently - we are proactive, friendly, and serious about delivering a great service. Here are some of the reasons Gravitas is different than other recruiters.</p><ul><li>True IT recruitment specialists: Each of our consultants only specialise in one technology area within IT development, building their expertise, and offering valuable insights and guidance to a broad range of businesses.</li><li>Fast-paced delivery, balanced with quality service: Our network of tech professionals gives businesses access to mid-senior level candidates across the Bavaria and Hesse areas. Our processes are efficient and backed by leading technology, automation, and an award-winning compliance and operations team.</li><li>We do recruitment the right way: The value of integrity cannot be underestimated. We ensure we listen, ask the right questions, provide open communication, and encourage feedback. Our processes are clear and transparent, following set compliance processes to build trust. We don’t cut corners!</li></ul>",
  "page.hiring.content.title.en": "Why partner with Gravitas.",
  "page.hiring.deliver.button.en": "Get in touch",
  "page.hiring.deliver.footer.en":
    "<strong>Are you ready to discover why we are so trusted and respected within the global recruitment community?</strong><br/>Contact our technology recruitment team today for a no obligation discussion about your needs.",
  "page.hiring.deliver.intro.en":
    "When you decide to work with us, we will start by booking a consultation to understand your hiring requirement or project, any challenges, asking detailed questions about the roles and responsibilities, along with your company culture. This will help us to create a solution and timeline that will meet your needs. We will manage your technology recruitment from start to finish.",
  "page.hiring.deliver.section-1.text.en":
    "<ul><li>Assigning an experienced IT recruitment specialist.</li><li>Regular communication to ensure you are up to date on progress.</li><li>Candidate attraction, accessing our network, seeking passive candidates, advertising and much more.</li><li>Qualification of interested candidates.</li><li>Production of a shortlist of the best talent, ready for you to review.</li><li>Communication with candidates to keep them engaged and to build your brand reputation, promoting you company culture and benefits.</li><li>Arranging suitable interviews and helping to prepare candidates for your process.</li><li>Liaising on interview success.</li><li>Offer negotiation.</li></ul>",
  "page.hiring.deliver.section-1.title.en": "Individual roles",
  "page.hiring.deliver.section-2.text.en":
    "<ul><li>Provide the same as for Individual Roles</li><li>Assign a dedicated point of contact to reduce your admin and ensure smooth communication.</li><li>Produce a wider plan of action tailored to your requirements. This could include prioritisation of roles, targeting recommendations, and insights to help you attract the best talent.</li><li>Assign a team of specialist recruiters to hire for your project, matching their specialist skills to your specialist tech roles.</li><li>Book regular meetings and reports to demonstrate progress, discussing tactics, and performance.</li></ul>",
  "page.hiring.deliver.section-2.title.en": "Team, project or department hiring",
  "page.hiring.deliver.title.en": "How will we deliver?",
  "page.hiring.insights.title.en": "Hiring solution insights.",
  "page.hiring.values.title.en": "What will we deliver?",
  "page.home.banner.links.title.en": "Specialisms",
  "page.home.banner.text.en":
    '<p>Gravitas Recruitment Group are global, IT recruitment specialists, located in the heart of London, Leeds and Manchester. We work with high-quality, junior to lead technology professionals, helping them to find their next contract or permanent role across IT development. Find out how we can help you.</p><p><a href="/jobs">Search our jobs</a> or <a href="/contact-us">register your details</a>.</p>',
  "page.home.banner.title.en": "Specialist Recruitment<br/>in the UK",
  "page.home.content.details.en":
    "<ul><li>We do recruitment the right way: Integrity matters; we ask the right questions, follow set processes, and form trusted relationships. We don’t cut corners!</li></ul>",
  "page.home.content.tag.en": "Serious about talent.",
  "page.home.content.text.en":
    "<p>There are many reasons to choose to work with Gravitas. Here are some of reasons we are different from other agencies:</p><ul><li>True IT recruitment specialists: Each of our consultants only specialising in one technology area, within IT development, building their expertise, and offering valuable insights and guidance in Java and C#, for example.</li><li>A balance of speed-to-market and quality: Our team have built a specialist candidate network, backed by an award-winning compliance and operations team.</li></ul>",
  "page.home.content.title.en": "Why choose Gravitas.",
  "page.home.diversity.tag.en": "Serious about Talent.",
  "page.home.diversity.text.en":
    "<p>A diverse workforce has been shown time and time again to improve productivity, creativity and innovation which ultimately leads to better success.</p><p>It is a key factor in employee retention with more than half of Europe’s employees saying that working with a diverse group of people contributes to their happiness at work.</p><p>Moreover, recent studies have shown that consumers are more likely to trust and support brands that demonstrate...</p>",
  "page.home.diversity.title.en": "Why it matters for businesses",
  "page.home.insights.title.en": "Marketing Insights",
  "page.dei.insights.title.en": "DEI Insights",
  "page.home.reasons.text.en": "3 reasons why you should partner with us and let us help you achieve excellence",
  "page.home.reasons.title.en": "Reasons why you should partner...",
  "page.home.services.businesses.button.en": "Learn more about our hiring solutions",
  "page.home.services.businesses.text.en":
    "<p>We work with small, medium, and large organisations, across a broad range of industries. When providing hiring solutions, we will contact you to learn about your role, its purpose, and criteria for hiring. We will also aim to gain a good understanding of your culture and values, so we can find the right fit for your business. <br />We will manage your IT recruitment process from start to finish, reducing your administration, improving your candidate quality, and helping you to create an IT development workforce that meets your business goals.</p>",
  "page.home.services.businesses.title.en": "Businesses: What to expect",
  "page.home.services.candidates.button.en": "Search jobs",
  "page.home.services.candidates.text.en":
    "<p>You can join our candidate community by registering your CV (link to registration), so we can match you with new IT development jobs that suit your skills and experience. We will then contact you to discuss what you are looking for. Alternatively, apply directly to one of our jobs via our website.</p><ul><li>Our services make your job search much easier. Avoid some of these common problems, and call us today.</li><li>Ever searched for a job for hours to find a suitable job description, only to find out in the interview that the role is different than you expected?</li><li>Ever started a new job where the tasks or the culture is different to what was described during the interview?</li><li>Ever realised you could have negotiated a significantly higher salary or rate?</li></ul><p>Our expert team can solve these issues for you, free of charge.</p>",
  "page.home.services.candidates.title.en": "Candidates: What to expect",
  "page.home.services.title.en": "Our Services",
  "page.impressum.content.en":
    "<p><strong>Gravitas Recruitment Group (Deutschland) GmbH</strong><br />Geschäftsführer: Guido Sieber<br />Registriert am Amtsgericht München: HRB 280248<br />Steuer Nr.: 143/142/43693<br />Adresse: Rosental 8, 80331 München<br />Tel.: +49 (0)89 215253611</p><p><strong>UK</strong><br />Gravitas Recruitment Group Limited<br />Registered Office: 6 Bevis Marks, London, EC3A 7BA<br />Registered Number:  06959984</p><p><strong>Hong Kong</strong><br />Gravitas Recruitment Group (Hong Kong) Limited<br />Registered Office: Flat/Rm 02-06, 31/F, Dorset House Taikoo Place, 979 King’s Road, Quarry Bay, Hong Kong.<br />Registered Number:  52528933-000-06-21-1</p><p><strong>Singapore</strong><br />Gravitas Recruitment Group (SG) Pte. Ltd<br />Registered Office: 9 Raffles Place, #16-03 Republic Plaza Tower 1, Singapore 048619<br />Registration Number.  201411749D<br />Recruitment Licence. 16S8141</p><p><strong>Shenzhen, China</strong><br />Gravitas Recruitment Group (Shenzhen) Limited<br />跨维人力资源服务(深圳)有限公司<br />Registered Office: D310, Maritime Terminal Building, 59 Linhai Ave, Nanshan, Shenzhen, Guangdong Province, China, 518000<br />Registration Number.  91440300MA5GHWDX3Q</p>",
  "page.impressum.title.en": "Impressum.",
  "page.insight.related.title.en": "You may be interested in...",
  "page.insights.banner.text.en":
    "Want to learn about all things recruitment, about the newest legislation, gain valuable knowledge about your sector or learn about current thinking on a variety of topics? Our insights are the perfect opportunity to stay updated with the latest developments, news and views and learn a little bit about Gravitas and who we are.",
  "page.insights.banner.title.en": "News & Insights.",
  "page.insights.item.button.en": "Read now",
  "page.insights.query.label.en": "Keyword",
  "page.insights.topics.label.en": "Filter by Tags",
  "page.insights.topics.placeholder.en": "e.g. 'cv advice'",
  "page.ir35.assessment.text.en":
    "<p>Use our Status Determination Tool to figure out if you are likely to fall inside or outside of IR35.<br />PLEASE NOTE that this is just an indication and not a final status determination.<br />To get a final determination all three parties would need to complete an assessment.</p>",
  "page.ir35.assessment.title.en": "Determine your status.",
  "page.ir35.banner.text.en":
    "<p>Welcome to our IR35 information hub, here you can find out all you need to know about the UK's off-payroll working legislation.</p><p>For more information about the new IR35 reforms announced September 2022 read our latest blog post.</p>",
  "page.ir35.banner.title.en": "Gravitas IR35",
  "page.ir35.content.tag.en": "Serious about Talent.",
  "page.ir35.content.text.en":
    "<p>Since 2000, major changes in taxation processes mean that how self-employed contractors are taxed have changed. When you work as a contractor, you can choose to operate through your own limited company or an umbrella company, and for the latter, we have an approved supplier list to help make the decision easy.</p><p>Recently, since April 2021, all public sector clients and medium and large private sector clients have been responsible for determining the IR35 status of the contractors they work with, so it’s key to understand the differences and make sure you know whether they fall inside or outside of IR35.</p>",
  "page.ir35.content.title.en": "About IR35.",
  "page.ir35.cta.1.text.en":
    "View our approved supplier list of umbrella companies, so you get an effective, worry-free service every time. ",
  "page.ir35.cta.1.title.en": "Gravitas Umbrella PSL/ASL",
  "page.ir35.cta.2.text.en":
    "A government Framework that Gravitas supplies IT professionals into the NHS and Public Sector through. We are on LOT3 RM6160 for IT Professionals",
  "page.ir35.cta.2.title.en": "Crown Commercial Service",
  "page.ir35.cta.3.text.en": "Our recommended Insurance provider for contractors for convenience and peace of mind.",
  "page.ir35.cta.3.title.en": "Kingsbridge",
  "page.ir35.cta.4.text.en": "Need further help with IR35? Get in touch with our teams today.",
  "page.ir35.cta.4.title.en": "Contact Us.",
  "page.ir35.insights.title.en": "IR35 Insights.",
  "page.java.section-1.text.en":
    "<p>We specialise in helping skilled, experienced, Java and IT development professionals find contract and permanent jobs across Germany, focusing on mid-senior level positions.<br />Our experienced, friendly team are based in Munich and Frankfurt, and have a deep understanding of the tech skills needed to thrive as a Java Developer. By providing expert advice, we will guide you through the recruitment process, helping you find a job and employer that meets your career goals. <br /> We work with clients on a mixture of site-based, remote and hybrid roles, across a broad selection of industries, so you can choose the right fit to suit your needs.</p>",
  "page.java.section-1.title.en": "How we can help.",
  "page.java.section-2.1.text.en":
    "<p>Here are some examples of the types of niche Java jobs we place with our clients across the Bavaria and Hesse areas:</p><ul><li>Java frameworks and libraries such as Spring, Spring Boot, Scala, Mockito, Kotlin Stdlib</li><li>Agile Development and CI/CD</li><li>Core programming concepts like Object-Oriented Programming, Data Structures & Algorithms</li><li>Java development tools e.g. Eclipse IDE, Maven, Git, Jenkins</li><li>Multithreading & Concurrency</li></ul>",
  "page.java.section-2.1.title.en": "Examples of the Java roles we recruit for",
  "page.java.section-2.2.text.en":
    "<p>Our clients are looking for a broad range of skills, depending on their specific role. Here are some of the hottest skills being hired for this year.</p><ul><li>Java frameworks and libraries such as Spring, Spring Boot, Scala, Mockito, Kotlin Stdlib</li><li>Agile Development and CI/CD</li><li>Core programming concepts like Object-Oriented Programming, Data Structures & Algorithms</li><li>Java development tools e.g. Eclipse IDE, Maven, Git, Jenkins</li><li>Multithreading & Concurrency</li></ul>",
  "page.java.section-2.2.title.en": "Hottest Java skills being hired for",
  "page.java.section-3.text.en":
    "<p>Java continues to be one of the most popular programming languages, and is in high demand, offering many benefits to developers choosing to work in this specialism. Here are some of the reasons our candidates enjoy working in Java:</p><ul><li>Java offers a competitive salary in Germany, depending on experience and location, ranging from €53K- €93K, averaging at €62,636 according to Glassdoor.</li><li>It’s used for cloud apps, machine-learning environments, mobile development, backend development and even the Internet of Things and Big Data, offering a range of rewarding career paths.</li><li>Java is secure and reliable, offering enhancements through object-oriented programming, garbage collection, platform independence, and a wide range of libraries and APIs.</li><li>This powerful programming language also offers flexibility and scalability, meaning it can suit the development of complex applications.</li></ul>",
  "page.java.section-3.title.en": "Why people love working in Java",
  "page.job.apply.link.en": "Apply",
  "page.job.details.contact.en": "Contact",
  "page.job.details.location.en": "Location",
  "page.job.details.published.en": "Published",
  "page.job.details.reference.en": "Ref",
  "page.job.details.salary.en": "Salary",
  "page.job.details.type.en": "Type",
  "page.job.details.workplace.en": "Workplace",
  "page.job.form.title.en": "Apply",
  "page.job.similar.title.en": "Similar jobs",
  "page.jobs.application-complete.content.en":
    "<p>Thank you for submitting your enquiry on our website. We will endeavor to get back to you within 1-2 working days.</p><p>Please note our working hours are 8.30am – 6pm, Monday to Friday.</p>",
  "page.jobs.application-complete.title.en": "Thank you",
  "page.jobs.banner.text.en": "",
  "page.jobs.banner.title.en": "Job search.",
  "page.jobs.card.button.en": "Apply",
  "page.modern-slavery-statement.content.en":
    "<h4>Modern Slavery Statement</h4><p>The Gravitas Modern Slavery Statement has been published in accordance with the Modern Slavery Act 2015. It sets out the steps taken by Gravitas Recruitment Group Limited (06959984) to prevent modern slavery and human trafficking within its business and within the supply chains through which it operates.</p><a href='https://www.gravitasgroup.co.uk/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBM1V3TFE9PSIsImV4cCI6bnVsbCwicHVyIjoiYmxvYl9pZCJ9fQ==--5200f0c1dc33f53168b794315fb899981a40ae4e/Modern%20Slavery%20Statement%202021.pdf'>View latest statement here</a>",
  "page.modern-slavery-statement.title.en": "Modern Slavery Statement",
  "page.permanent.banner.button.en": "Get in touch",
  "page.permanent.banner.text.en":
    "We have been helping businesses globally with their permanent recruitment solutions since 2010, specialising in IT development roles. We work with businesses across Germany through our offices in Munich and Frankfurt, building a strong network of candidates in and around the surrounding areas.",
  "page.permanent.banner.title.en": "Permanent Recruitment<br/> Agency - Tech Specialists",
  "page.permanent.content.tag.en": "Serious about talent.",
  "page.permanent.content.text.en":
    "<p>We are proud to be different than other permanent recruitment agencies, through our friendly, proactive, and transparent approach. What makes us different?</p><ul><li>True technology recruitment experts: Our recruitment consultants each specialise in one technology area, so they can build their knowledge, and share industry insights with our clients.</li><li>Speed balanced with quality: We ensure we deliver our services efficiently, and through our IT candidate network we can quickly reach quality talent, that other agencies might not have access to.</li></ul>",
  "page.permanent.content.title.en": "Why choose Gravitas.",
  "page.privacy-policy.content.en":
    "<h4>Privacy statement</h4><p>Gravitas Recruitment Group Limited is the sole owner of the information collected on this site. We will not sell, share, or rent this information to others in ways that differ from that disclosed in this statement. Gravitas Recruitment Group Limited collects information from our users at a number of locations on the site and within a number of sites on the web.</p><h4>Contacting Gravitas Recruitment Group Limited Electronically</h4><p>In order to get in touch with us electronically, a user can email a specific address as listed in the site or complete a contact form, if available. Within this, a user is required to give their contact information i.e. email address etc. This information may be used to contact the user about available services.</p>",
  "page.privacy-policy.title.en": "Privacy statement.",
  "page.register.banner.text.en": "",
  "page.register.banner.title.en": "Candidate Form",
  "page.register.form.title.en": "Register your details",
  "page.specialism.content.tag.en": "Serious about Talent.",
  "page.specialism.technology.banner.text.en":
    "<p>We’re Technology recruitment specialists. With niche teams focusing on specific technology skill sets, we utilise our IT recruitment knowledge to ensure we match the right IT people with the right IT jobs.</p><p>Specific areas of expertise include:</p><ul><li><a href='/specialisms/technology/development'>Development</a></li><li><a href='/specialisms/technology/public-sector'>Public Sector</a></li><li><a href='/specialisms/technology/change-and-transformation'>Change & Transformation</a></li></ul>",
  "page.specialism.technology.banner.title.en": "Technology Recruitment",
  "page.specialism.technology.content.details.en":
    "<p>Key IT jobs we cover include; DevOps Engineer, Data Engineer, Mobile Engineer, .Net Developer, IOS Developer, Android Developer, Java Developer, Business Analyst, and Project Manager.</p><p>We couple our sector knowledge and networks, alongside our core values of Respect, Integrity, Passion and Excellence; to deliver an unrivalled service, and strive to create solutions to empower your ambitions.</p>",
  "page.specialism.technology.content.text.en":
    "<p>We work hard to create extensive professional networks across the Technology Sector and build an in-depth knowledge of niche areas of recruitment, our service areas and our customers.</p><p>Our consultants work in dedicated teams covering things like Data, Development, DevOps, Change, Transformation, Digital and Cybersecurity.</p>",
  "page.specialism.technology.content.title.en": "Technology Recruitment Services",
  "page.specialism.technology.insights.title.en": "Technology Insights.",
  "page.specialisms.banner.title.en": "Our Specialisms.",
  "page.submission-complete.content.en":
    "<p>Thank you for submitting your enquiry on our website. We will endeavor to get back to you within 1-2 working days.</p><p>Please note our working hours are 8.30am – 6pm, Monday to Friday.</p>",
  "page.submission-complete.title.en": "Thank you",
  "page.team.banner.text.en":
    "Gravitas Recruitment Group is honoured to have received distinctive recognition for our world-class talent recruitment services within the human resources industry.",
  "page.team.banner.title.en": "Meet the Gravitas team.",
  "page.team.filter.location.placeholder.en": "Filter by location",
  "page.team.filter.speciality.placeholder.en": "Filter by speciality",
  "page.team.link.en": "Meet the team",
  "page.team.subtitle.en": "Meet the team.",
  "page.team.text.en": "",
  "page.team.title.en": "Meet the Gravitas team.",
  "page.work4us.banner.buttons.1.en": "Latest Roles",
  "page.work4us.banner.buttons.2.en": "Trainee Recruitment Consultant",
  "page.work4us.banner.buttons.3.en": "Senior Recruitment Consultant",
  "page.work4us.banner.text.en":
    "<p>We are Gravitas. A global solutions company serious about talent, and serious about providing specialist recruitment and consultancy services. In Germany, The Netherlands and the UK we work within key niche talent shortage areas in the Technology, Insurance, Actuarial and Public Sectors.</p><p>Our aim is to be a trusted partner, an employer of choice and to deliver an unrivalled service across contract, permanent recruitment and our consultancy solutions.</p>",
  "page.work4us.banner.title.en": "Work for us.",
  "page.work4us.benefits.title.en": "Why join us?",
  "page.work4us.careers.link.en": "Meet our team",
  "page.work4us.careers.subtitle.en": "We are <span>Gravitas</span>.",
  "page.work4us.careers.text.en":
    "<p>We are <a href='#'>serious about talent</a> and serious about providing professional and financially and personally rewarding careers in recruitment.</p><p>Whether you're considering your first job in recruitment, your next career move, or are a seasoned recruiter, we can provide a wealth of global opportunities.</p><p>We also offer a diverse and inclusive culture, Princess Royal accredited, multi-award-winning training, uncapped earning potential and a range of other benefits.</p><p>Our‘Gravitonians’ are seriously motivated, resilient and dedicated individuals who are passionate about going beyond recruitment, connecting communities and transforming lives.</p><p><a href='#'>Learn More Here</a></p>",
  "page.work4us.careers.title.en": "Gravitas careers.",
  "page.work4us.content.tag.en": "Serious about talent.",
  "page.work4us.insights.title.en": "Life@Gravitas",
  "page.work4us.offices.title.en": "Our offices",
  "profile.card.link.en": "Profile",
  "section.awards.text.en":
    'We’ve won numerous awards for every aspect of our business. <a href="/about-us/awards">Visit our awards page</a>.',
  "section.awards.title.en": "Awards & Accolades",
  "section.case_studies.title.en": "Case Studies.",
  "section.clients.title.en": "Our clients.",
  "section.events.title.en": "Upcoming Events",
  "section.expectations.contract.footer.en": "",
  "section.expectations.contract.text.en":
    "<p>At Gravitas, we leverage the power of recruitment technology and AI to streamline the recruitment process and reduce administrative burdens. Our award-winning compliance and operations team are on hand to process contracts, advise on legalities and set up time sheeting and invoicing, ensuring quality and speed. We take accuracy, compliance, and safety seriously and provide the necessary tools and support to our team and clients.</p>",
  "section.expectations.permanent.footer.en": "",
  "section.expectations.permanent.text.en":
    "<p>Recruiting your own permanent workforce can be very difficult, time-consuming, and costly, especially when recruiting for specialist IT roles.</p>",
  "section.expectations.title.en": "What to expect?",
  "section.history.title.en": "Our history.",
  "section.insights.text.en":
    "Gain insights into a range of topics, through our blog posts, news articles, podcasts and videos. \n" +
    "Covering all things recruitment, alongside market news, and career development, our insights also provide you with an opportunity to learn a little bit about Gravitas and who we are.",
  "section.insights.title.en": "Marketing Insights",
  "section.latest_jobs.all.en": "View all",
  "section.latest_jobs.title.en": "Latest Roles",
  "section.recruitment_process.contract.title.en": "Freelance Recruitment Process",
  "section.recruitment_process.permanent.details.en":
    "<p>... clear, helping to manage your expectations. We combine our process with regular communication to keep your candidates engaged, while providing you with regular updates on progress. Our process can include:</p>",
  "section.recruitment_process.permanent.footer.en":
    'Find out why our clients and candidates love working with our team and recommend us to their colleagues. <br/><a href="/contact-us">Contact our permanent recruitment team</a> today for a no obligation discussion about your needs.',
  "section.recruitment_process.permanent.text.en": "<p>Our recruitment process is straight-forward and ...</p>",
  "section.recruitment_process.permanent.title.en": "Our permanent recruitment process",
  "section.solutions.contract.text.en": "",
  "section.solutions.contract.title.en": "Contract Recruitment",
  "section.solutions.permanent.text.en": "",
  "section.solutions.permanent.title.en": "Permanent Recruitment",
  "section.solutions.title.en": "Hiring Solutions",
  "section.specialisms.insurance.menu.text.en":
    "We are niche insurance recruitment specialists working in partnership with businesses and clients across a range of fields.",
  "section.specialisms.technology.menu.text.en":
    "As niche technology recruitment specialists we work collaboratively with businesses and clients across a range of fields.",
  "section.specialisms.banking.menu.text.en":
    "We are niche banking recruitment specialists working in partnership with businesses & clients across a range of areas.",
  "section.specialisms.title.en": "Our Specialisms",
  "section.statistics.title.en": "Our numbers.",
  "section.testimonials.title.en": "Testimonials.",
  "section.values.title.en": "Our values.",
  "share.label.en": "Share this",
  "sidebar.form.title.en": "Search 0 jobs",
  "sidebar.sectors.title.en": "View jobs by speciality",
  "specialism.link.back.en": "Back to specialisms",
  "team.link.back.en": "Back to meet the team",
  "theme.jobs.search.en": "Search Jobs",
  "widget.event.search.button.en": "Search events",
  "widget.event.search.placeholder.en": "Search all events",
  "widget.event.search.query.label.en": "Keywords",
  "widget.event.search.tag.label.en": "Filter by tag",
  "widget.event.tags.placeholder.en": "All",
  "page.sitemap.banner.title.en": "Sitemap",
};
